import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import {
    motion,
    useMotionValue,
    useTransform,
    useViewportScroll,
    useSpring,
} from 'framer-motion'
import useMeasure from 'react-use-measure'
import Marquee, { Motion, randomIntFromInterval } from 'react-marquee-slider'
import times from 'lodash/times'
import { useMount } from 'react-use'

import { Layout, Ticker } from '../components'
import { container, padding, colours, type } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import { parseACF } from '../utils'

const Heroes = (props) => {
    const [theme, setTheme] = useState('red')
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [isMobile, setIsMobile] = useState(false)
    const detectMobile = useBreakpoint('phone')

    // Check if Mobile

    useMount(() => {
        setIsMobile(detectMobile)
    })

    useEffect(() => {
        setIsMobile(detectMobile)
    }, [detectMobile])

    // Render Friends

    const renderFriends = () => {
        if (!data.friends) return

        const items = data.friends.map((item, i) => {
            return (
                <Item
                    key={i}
                    content={item.text}
                    velocity={isMobile ? 10 : randomIntFromInterval(70, 130)}
                    //velocity={randomIntFromInterval(70, 130)}
                    direction={Math.random() > 0.5 ? 'rtl' : 'ltr'}
                />
            )
        })

        return <List>{items}</List>
    }

    return (
        <Layout meta={data && data.seo} theme={theme} hideFooter={true}>
            <Container>{renderFriends()}</Container>
        </Layout>
    )
}

// Shared

const List = styled.div``
const Item = styled(Ticker)``

// Layout

const Container = styled.div`
    ${container};
    flex-direction: column;
    background: ${colours.red};
    padding-top: 20px;
    padding-bottom: 20px;

    ${media.tablet`
		padding-top: 80px;
	`}

    ${List} {
        display: flex;
        flex-direction: column;

        ${Item} {
            font-size: 90px;
            line-height: 1.1;
            margin: 0;
        }
    }
`

// Query

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "friends" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Heroes
